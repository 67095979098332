@charset 'UTF-8';

@import
	'abstracts/variables',
	'abstracts/functions',
	'abstracts/mixins';

@import
  'bootstrap/scss/bootstrap';

@import
	'base/base',
	'base/fonts',
	'base/typography',
	'base/dividers',
	'base/helpers',
	'base/utility';

@import
	'components/navigation',
	'components/header',
	'components/footer',
	'components/forms',
	'components/buttons',
	'components/banners',
	'components/backgrounds',
	'components/page-title',
	'components/pagination',
	'components/video',
	'components/blocks';

@import
	'templates/home',
	'templates/events',
	'templates/news',
	'templates/volunteer';
