header nav .nav-tabs {
	position: relative;
	z-index: 50;
}
header nav .navbar-nav {
}

header nav .nav-link {
	color: $gray-dark1;
	font-family: "Montserrat", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-size: 16px;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	@media (max-width: 1300px) {
		font-size: 14px !important;
	}
	@media (max-width: 1130px) {
		font-size: 12px !important;
	}
}

header nav .nav-link {
	color: $gray-dark1;
	padding: 0 30px 6px 30px !important;
}

header nav .nav-item {
	margin: 0px;

	&:hover {
		.nav-link {
			color: $brandcolor1;
		}
	}
}

.navbar-toggler {
	//border: 2px solid $gray-dark2 !important;
	//display: none; /* temporary for the one-page site. Delete to have mobile navigation again */
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(99,84,80,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#header-menu {
	li {
		display: block;
		transition-duration: 0.5s;
		&:hover {
			cursor: default;
			&.pointer {
				cursor: pointer;
			}
		}
	}


	ul li ul {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		transition: all 0.5s ease-out;
		display: none;
		a {
			color: $white;
			background-color: $gray-dark2;
			border: 1px solid $white;
			padding: 10px 30px !important;
		}
	}

	ul li:hover > ul,
	ul li ul:hover {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	ul li ul li {
		clear: both;
		width: 100%;
	}
}
