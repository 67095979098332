#homeHeroBannerSlides {
	@include media-breakpoint-up(lg) {
		height: 700px !important;
	}
}

.entry-pages-home { 
	.page-content {
		padding: 0;
		@media (min-width: 768px) {
			padding: 0;
		}
	}

	#block-about {
		h2 {
			text-transform: uppercase;
			font-size: 2.5em;
			font-weight: 600;
			color: $gray-dark1;
			margin-top: 1.0em;
			strong {
				color: $brandcolor2;
			}
			small {
				display: block;
				color: $gray-mid3;
				text-transform: none;
				font-size: 0.65em;
				font-weight: 300;
			}
		}
		p {
			font-size: 1.0em;
			line-height: 1.25em;
		}
	}

	#block-contribute-header {
		.block-headline-text {
			display: inline;
			font-size: 2.5em;
			font-weight: 700;
		}
		.block-subheadline-text {
			display: inline;
			color: $gray-dark1;
			font-size: 2.0em;
			font-weight: 600;
			padding: 0 0.25em
		}
	}
	#block-contribute {
		.card {
			margin: 0 !important;
			@media (max-width: 992px) {
				margin: 25px 0 !important;
			}
		}
		.btn  {
			position: absolute;
			bottom: -25px;
			width: -webkit-calc(100% - 40px);
			width:    -moz-calc(100% - 40px);
			width:         calc(100% - 40px);
			font-size: 1.4em;
			font-weight: 400;;
		}
	}
}
