.btn {
	line-height: 100%;
	border-radius: 0;
	font-size: 16px;
	padding: 15px 30px;
}

.btn-sm {
	font-size: 12px;
	padding: 15px 30px;
}

.btn-lg {
	font-size: 20px;
	padding: 20px 40px;
}

.btn-primary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: $brandcolor1;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-dark;
		border: none;
		// &::after {
		// 	-webkit-transition: transform 0.5s ease-out;
		// 	-moz-transition: transform 0.5s ease-out;
		// 	-ms-transition: transform 0.5s ease-out;
		// 	-o-transition: transform 0.5s ease-out;
		// 	transition: .5s ease-out;
		// 	margin-left: 10px;
		// 	opacity: 100%;
		// }
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light;
		border: none;
		box-shadow: 0;
		outline: none;
	}
	// &::after {
	// 	display: inline-block;
	// 	position: relative;
	// 	font-style: normal;
	// 	font-variant: normal;
	// 	text-rendering: auto;
	// 	-webkit-font-smoothing: antialiased;
	// 	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f105";
	// 	margin-left: -5px;
	// 	opacity: 0%;
	// }
}

.btn-secondary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: $brandcolor2;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor2-dark;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor2-light;
		border: none;
		box-shadow: 0;
		outline: none;
	}
}

.btn-dark {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: $brandcolor3;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor3-dark;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor3-light;
		border: none;
		box-shadow: 0;
		outline: none;
	}
}

.btn-outline-primary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	background-color: none;
	color: $brandcolor1;
	border: 1px solid $brandcolor1;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-dark;
		border: 1px solid $brandcolor1-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor1-light;
		border: 1px solid $brandcolor1-light;
	}
}

.btn-outline-secondary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	color: $gray-dark1;
	border: 1px solid $brandcolor2;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor2-dark;
		border: 1px solid $brandcolor2-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor2-light;
		border: 1px solid $brandcolor2-light;
	}
}

.btn-dark {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	background-color: $brandcolor3;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor3-dark;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor3-light;
		border: none;
	}
}

.btn-light {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	background-color: $white;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor1-dark;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor1-light;
		border: none;
	}
}

.btn-outline-dark {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	background-color: none;
	color: $black;
	border: 1px solid $brandcolor3-dark;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor3-dark;
		border: 1px solid $brandcolor3-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor3-light;
		border: 1px solid $brandcolor3-light;
	}
}

.btn-outline-light {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	background-color: none;
	color: $white;
	border: 1px solid $white;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor1-dark;
		border: 1px solid $brandcolor1-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $white;
		background-color: $brandcolor1-light;
		border: 1px solid $brandcolor1-light;
	}
}

.btn-link {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	color: $brandcolor1;
	text-decoration: none;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $brandcolor1;
		text-decoration: underline;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		color: $brandcolor1-dark;
		text-decoration: underline;
		border: none;
	}
}