.article-card {
	.post-date {
		font-size: 0.9em;
		line-height: 1;
	}
	.card-text {
		font-size: 0.95em;
		line-height: 1.25;
	}
	.article-link {
		font-size: 0.9em;
		line-height: 1;
	}
}
.article-card-image {
	background-color: rgba(255, 255, 255, 0.0) !important;
	overflow: hidden;
	min-height: 290px !important;
	&:hover {
		background-color: rgba(255, 255, 255, 0.25) !important;		
	}
	.card-img-overlay {
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
	.card-body {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.8) !important;
		backdrop-filter: blur(3px);
		&:hover {
			background-color: rgba(255, 255, 255, 0.9) !important;		
			backdrop-filter: blur(6px);
		}
	}
	.card-text {
		display: none;
	}
	.card-title {
		font-size: 1.2em;
		margin-bottom: 1.25em;
	}
	.post-date {
		font-size: 0.8em;
		line-height: 1;
	}
	.article-link {
		font-size: 0.8em;
	}
}