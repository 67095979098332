// .header-full {
// 	background-color: $white;
// 	position: sticky;
// 	z-index: 100;
// 	width: 100%;
// 	height: 105px;
// 	border-top: 5px solid $brandcolor1;
// }

// .header-container {
// 	padding: 0px 40px;
// }

// header .navbar-brand {
// 	width: 360px;
// 	height: 114px;
// 	background: url("/assets/images/branding/logo-on-white.svg") center center/contain no-repeat;
// 	text-indent: -9999px;
// 	margin: 10px 10px;
// 	z-index: 3;
// 	@media (max-width: 768px) {
// 		top: 5px;
// 		width: 270px;
// 		height: 86px;
// 	}
// }

// .header-bottom {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	background: url("/assets/images/backgrounds/top-stripe-white.png") top center/cover no-repeat;
// 	z-index: 2;
// 	width: 100%;
// 	height: 40px;
// }