.event-date-card {
	display: inline-block;
	border-top: 1.25rem solid $brandcolor1;
	background: linear-gradient(to bottom, transparent 0, transparent 2px, $brandcolor2 2px, $brandcolor2 100%);
	text-align: center;
	color: $white;
	padding: 1em 0;
	text-transform: uppercase;
	line-height: 1;
	font-weight: 700;
	.event-month,
	.event-year {
		font-size: 0.9em;
	}
	.event-day {
		font-size: 2.8em;
		margin: 0.1rem 0;
		width: 2.5em;
	}
}

.event-text-card {
	h3 {
		margin-bottom: 0.1em;
	}
	p {
		font-size: 0.95em;
		line-height: 1.25;
	}
	.article-link {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 0.9em;
		line-height: 1;
	}
}

.event-detail-label {
	color: $brandcolor1-light;
	font-weight: 600;
}

.featured-event {
	background-color: $brandcolor3-extralight;
	padding: 20px 0;
	margin: 0 0 -16px -15px;
	.event-date-card {

		// background: linear-gradient(to bottom, transparent 0, transparent 2px, $brandcolor2-dark 2px, $brandcolor2-dark 100%);
	}
	h3 {
		font-weight: 600;
	}
	h4 {
		color: $brandcolor1;
	}
}
hr {
	&.divider-brand {
		border-color: $brandcolor3;
	}
	&.divider-brand-alt {
		border-color: $brandcolor2;
	}
}