hr.divider-left {
	overflow: visible; /* For IE */
	border: none;
	border-top: 1px solid $brandcolor1;
	color: $brandcolor1;
	text-align: left;
}
// hr.divider-left:before {
// 	content: "–";
// 	position: relative;
// 	top: 0.050em;
// 	left: -0.070em;
// 	font-size: 5em;
// 	line-height: 0em;
// }

hr.divider-right {
	overflow: visible; /* For IE */
	border: none;
	border-top: 1px solid $brandcolor1;
	color: $brandcolor1;
	text-align: right;
}

// hr.divider-right:after {
// 	content: "––";
// 	display: inline-block;
// 	position: relative;
// 	top: -0.040em;
// 	right: 0em;
// 	font-size: 5em;
// 	line-height: 0em;
// }

hr.divider-both {
	overflow: visible; /* For IE */
	margin: 0px;
	padding: 0px;
	border: none;
	text-align: right;
}
// hr.divider-both:after {
// 	content: "––";
// 	display: inline-block;
// 	position: relative;
// 	top: -0.040em;
// 	right: 0em;
// 	font-size: 5em;
// 	line-height: 0em;
// }
.divider-both-container {
	overflow: visible; /* For IE */
	padding-top: 0px;
	padding-bottom: 20px;
	text-align: left;
}
// .divider-both-container:before {
// 	content: "––";
// 	position: relative;
// 	top: 0.04em;
// 	left: 0em;
// 	font-size: 5em;
// 	line-height: 0em;
// }

hr.divider-white {
	border-top: 1px solid $white;
	color: $white;
}
.divider-white-container {
	color: $white;
}

hr.divider-brand {
	border-top: 1px solid $brandcolor1;
	color: $brandcolor1;
}
.divider-brand-container {
	color: $brandcolor1;
}

hr.divider-gray {
	border-top: 1px solid $gray-light3;
	color: $gray-light3;
}
.divider-gray-container {
	color: $gray-light3;
}