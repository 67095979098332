table.opportunity-role-shift-table {
	width: 100%;
	thead {
		border-bottom: 1px solid $gray-light3;
		th {
			padding: 0.625rem;
		}
	}
	tbody {
		tr {
			border-bottom: 1px solid $gray-white3;
			td {
				padding: 0.625rem;
				line-height: 1;
				label {
					margin: 0;
				}
			}
		}
		th.opportunity-role-shift-value {
		
		}
		td.opportunity-role-shift-value {
			input {
				margin: 0;
				transform: translateY(-0.3125rem);
			}
		}
	}
}