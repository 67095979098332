.banner-top {
	position: absolute;
	top: 0;
	right: 0;
	background: url("/assets/images/backgrounds/top-stripe-white.png") center center/cover no-repeat;
	z-index: 200;
	width: 100%;
	height: 20px;
}

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
}

.carousel-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding:40px;
	text-align: center;
	background-color: rgba(51, 51, 51, 0.15);
	background-blend-mode: multiply;
	background-size:cover;
	background-repeat: no-repeat;
	background-position:center center;
}

.carousel-caption {
	position: absolute;
	bottom:40%;
	left:50%;
	width: 66%;
	transform: translateX(-50%);
	padding:30px 20px;
	background-color:rgba(0,0,0,0.5);
	position: absolute;
	h5,h1,p {
		font-family: "proxima-nova", sans-serif;
		color: $white;
		font-weight: 400;
	}
	h5 {
		font-size: 2.0em;
	}
	h1 {
		margin: 0 0 0.5em 0;
		font-size: 4.0em;
		small {
			display:block;
			color:$brandcolor1-light
		}
	}
	p {
		font-family: "proxima-nova", sans-serif;
		font-size: 24px;
		line-height: 1;
		font-weight: 200;
		margin-bottom:30px;
		strong {
			font-weight: 700;
			color: $brandcolor1-light;
		}
	}
	.btn-primary {
		background: none;
		text-transform: none;
		font-weight: 400;
		border: 1px solid $brandcolor1-light;
		&:hover, &:focus {
			background-color:$brandcolor1-light;
			border: 1px solid $brandcolor1-light;
		}
		&::after {
			display: inline-block;
			position: relative;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "FontAwesome"; font-weight: 900; content: "\f105";
			margin-left: 0.5em;
			transform: translateY(0.05em);
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 20px;
	background-image: none;
  border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 42px;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 42px;
}