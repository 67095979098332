.form-text {
	font-size: 0.9em;
	margin-top: -0.25em;
	margin-bottom: 0.75em;
}

input[type="radio"],
input[type="checkbox"] {
	// width: 1em;
	// height: 1em;
	margin-top: 0;
	&#form-input-volunteerAgreement,
	&#form-input-membershipAgreement,
	&#form-input-subscribe {
		width: auto;
		height: auto;
		vertical-align: baseline;
	}
}
label {
	&[for="form-input-volunteerAgreement"],
	&[for="form-input-membershipAgreement"],
	&[for="form-input-subscribe"] {
		vertical-align: baseline;
	}
}

.form-check {
	margin-bottom: 0.625rem;
	.form-check-input:not(:checked), .form-check-input:checked {
	}
}

label.required:after  {
	content: "*";
 	color: $brandcolor3;
 	font-size: 1.5rem;
}

.form-label-required {
	color: $brandcolor3;
}

.form-required {
	color: $brandcolor3;
}

// input {
// 	width: 100%;
// 	padding: 10px;
// 	border: 1px solid $gray-light3;
// 	border-radius: 6px;
// }

// textarea {
// 	width: 100%;
// 	padding: 10px;
// 	border: 1px solid $gray-light3;
// 	border-radius: 6px;
// }

// .form-label-required {
// 	color: $brandcolor1-dark;
// }

// .form-check-input:not(:checked), .form-check-input:checked {
// 	position: absolute;
// 	pointer-events: none;
// 	opacity: 0;
// }

// .btn-group-toggle {
// 	margin: 0px 20px;
// }

// .btn-outline-group {
// 	-webkit-transition: transform 0.5s ease-out;
// 	-moz-transition: transform 0.5s ease-out;
// 	-ms-transition: transform 0.5s ease-out;
// 	-o-transition: transform 0.5s ease-out;
// 	transition: .5s ease-out;
// 	font-weight: 400 !important;
// 	text-transform: none !important;
// 	letter-spacing: 0 !important;
// 	background-color: none !important;
// 	color: $gray-dark2 !important;
// 	border: 1px solid $gray-light3 !important;
// 	&:hover {
// 		-webkit-transition: transform 0.5s ease-out;
// 		-moz-transition: transform 0.5s ease-out;
// 		-ms-transition: transform 0.5s ease-out;
// 		-o-transition: transform 0.5s ease-out;
// 		transition: .5s ease-out;
// 		background-color: $brandcolor1 !important;
// 		border: 1px solid $gray-light3 !important;
// 	}
// 	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
// 		-webkit-transition: transform 0.5s ease-out;
// 		-moz-transition: transform 0.5s ease-out;
// 		-ms-transition: transform 0.5s ease-out;
// 		-o-transition: transform 0.5s ease-out;
// 		transition: .5s ease-out;
// 		background-color: $brandcolor1-light !important;
// 		border: 1px solid $gray-light3 !important;
// 		box-shadow: 0 !important;
// 		outline: none !important;
// 	}
// }

// .btn-outline-group:not(:disabled):not(.disabled):active,
// .btn-outline-group:not(:disabled):not(.disabled).active,
// .show>.btn-outline-group.dropdown-toggle {
// 	color: $white !important;
//   background-color: $gray-dark1 !important;
//  }
