.img-single-image {
	max-height: 85vh;
}

// Blocks General
.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 5px 0 0 0;
	text-transform: none;
	line-height: 1;
}

// Text-With-Image Block
.image-overlay-sections {
	position: absolute;
	right: 40px;
	top: 0px;
	@media (max-width: 992px) {
		position: static;
		padding: 20px;
	}
}

.text-with-image-body {
}

.large-text-callout-body {
	p {
		font-size: 2.5em;
		line-height: 1.2em;
	}
}


// Accordion List Block
.accordion-block {
	.card {
		margin: 0;
		border: none;
		.card-header {
			margin: 0;
			padding: 0.5625em 0.5625em 0.3125em 0.5625em;
			background: none;
			border: none;
			border-bottom: 1px solid $gray-light2;
			.btn {
				margin: 0.25em 0;
				padding: 0;
				font-weight: 400;
				font-size: 2em;
				line-height: 1;
				color: $brandcolor1;
				text-transform: none;
				letter-spacing: 0;
				h3 {
					margin: 0 2.0rem 0 0;
					padding: 0;
				}
				.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0 !important;
					background-image: none !important;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
				}
				&[aria-expanded="true"] {
					.fa { transform: rotate(180deg); }
				}
				.fa {
					display: inline-block;
					position: absolute;
					top: 0.5625em;
					right: 0.5625em;
					margin: -0.1625em 0 0 0;
					padding: 0;
					line-height: 1;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					color: $brandcolor2;
				}
			}
		}
		.card-body {
			font-size: 1.0rem;
			padding: 1.25em 0.9375em 1.25em 0.9375em;
			border-bottom: 1px solid $gray-light2;
			p { font-size: 1.0rem; }
			h3 { 
				font-size: 1.875rem;
				color: $gray-dark1;
			}
		}
	}
}


//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 10px;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $gray-mid3;
}


//Pull Quote
.pull-quote-body p {
	font-size: 2.5em;
	line-height: 1.2em;
}
.pull-quote-citation {
	font-size: 1.2em;
	line-height: 1em;
}


//Image Callout Cards
.image-callout-card {
	border: 1px solid $gray-light3;
	border-radius: 0px;
	-webkit-box-shadow: 2px 2px 8px 0px rgba(36,36,35,0) !important;
	-moz-box-shadow: 2px 2px 8px 0px rgba(36,36,35,0) !important;
	box-shadow: 2px 2px 8px 0px rgba(36,36,35,0) !important;
	&:hover {
		-webkit-box-shadow: 2px 2px 8px 0px rgba(36,36,35,0.2) !important;
		-moz-box-shadow: 2px 2px 8px 0px rgba(36,36,35,0.2) !important;
		box-shadow: 2px 2px 8px 0px rgba(36,36,35,0.2) !important;
	}
}
.image-callout-headline {
}
.image-callout-body p {
	font-size: 1.0em;
	line-height: 1.2em;
}



//Image Icon Row
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-headline {
	color: $gray-mid3;
}

.image-icon-text p {
	color: $gray-dark2;
	line-height: 1.2em;
	padding-top: 10px;
}
.image-icon-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


