.body, body { 
	font-family: "Roboto", Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
	color: $gray-dark2;
}

.body {
	ul {
		list-style: none; /* Remove default bullets */
		margin: 1.0em 0;
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor1; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5em;  /* Also needed for space (tweak if needed) */
		// margin-left: -2.5em; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25em);
		font-size: 0.75em;
	}

	ul li {
		line-height: 1.25;
		margin: 0.9375em -0.5em;
		padding-left: 0.5em;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}
}

p {
	font-size: 18px;
	line-height: 1.5;
}

p.lineshort {
	line-height: 1.25;
}

a {
	color: $brandcolor1-light;
	&:hover {
		color: $brandcolor1;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: "proxima-nova", sans-serif;
	font-weight: 500;
}
h1 {
	font-size: 3em;
	line-height: 1;
}

h2 {
	font-size: 2.5em;
	line-height: 1;
	color: $gray-dark1;
}

h3 {
	font-size: 2.25em;
	line-height: 1;
}

h4 {
	font-size: 2em;
	line-height: 1;
}

h5 {
	font-size: 1.75em;
	line-height: 1;
}

h6 {
	font-family: "Roboto Condensed", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 700;
	font-size: 1.5em;
	line-height: 1;
	text-transform: uppercase;
}

.text-light, .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light ul li::before, .text-light ul li::before {
	color: $white;
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 10px;
}

h4.page-subheadline-text {
	margin-top: 10px;
}
